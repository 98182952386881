import { BreakpointObserver } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, DestroyRef, ElementRef, Input, OnInit, ViewChild, effect, inject, signal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { environment } from '@environment';
import { AvcMapRadialMenuService, AvcMapsDisplayService, InternetTrafficInfo } from '@garmin-avcloud/avc-maps-display';
import { FlyButtonModule } from '@garmin-avcloud/avcloud-ui-common/button';
import { FlightIcons, FlyIconModule, Icons } from '@garmin-avcloud/avcloud-ui-common/icon';
import { FlyInputComponent, FlyInputModule } from '@garmin-avcloud/avcloud-ui-common/input';
import { FlyLoadingSpinnerModule } from '@garmin-avcloud/avcloud-ui-common/loading-spinner';
import { Breakpoints } from '@garmin-avcloud/avcloud-ui-common/style-variables';
import { FlyTabsModule } from '@garmin-avcloud/avcloud-ui-common/tabs';
import { AuthService, isStringNonEmpty } from '@garmin-avcloud/avcloud-web-utils';
import { FlightRouteControllerService, RouteComputedLeg, UnifiedTokenRequest, UnifiedTokenRequestDesiredLocationTypes, UnifiedTokenResponse } from '@generated/flight-route-service';
import { TOKEN_SEARCH_TYPES } from '@shared/constants/flights/flights-constants';
import { State } from '@shared/enums/loading-state.enum';
import { AcdcAircraftTrackResponse } from '@shared/models/acdc-realtime-traffic/acdc-aircraft-track-response.model';
import { AirportSearchResponse } from '@shared/models/airport/search/airport-search-response.model';
import { AirportSearchResult } from '@shared/models/airport/search/airport-search-result.model';
import { AcdcRealtimeTrafficService } from '@shared/services/acdc-realtime-traffic/acdc-realtime-traffic.service';
import { AirportService } from '@shared/services/airport/airport.service';
import { AirportDataService } from 'projects/avcloud-pilotweb/src/app/features/airport/shared/services/airport-data.service';
import { Observable, combineLatest, debounceTime, distinctUntilChanged, filter, fromEvent, of, switchMap, tap } from 'rxjs';
import { AIRPORT_CONTENT_SIGNAL } from '../../../features/airport/tokens/airport-content.token';
import { AirportContentPath } from '../../../features/airport/types/airport-content.types';
import { InfoService, InfoWindowPosition } from '../../services/map-info/info.service';
import { AircraftSearchCardComponent } from './aircraft-search-card/aircraft-search-card.component';
import { AirportSearchCardComponent } from './airport-search-card/airport-search-card.component';
import { SearchCardComponent } from './search-card/search-card.component';

export interface SearchResult {
  airport?: AirportSearchResult;
  waypoint?: RouteComputedLeg;
  aircraft?: AcdcAircraftTrackResponse;
}

@Component({
  selector: 'pilot-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  standalone: true,
  imports: [
    AirportSearchCardComponent,
    CommonModule,
    FormsModule,
    FlyButtonModule,
    FlyInputModule,
    FlyIconModule,
    FlyLoadingSpinnerModule,
    FlyTabsModule,
    ReactiveFormsModule,
    SearchCardComponent,
    AircraftSearchCardComponent
  ]
})
export class SearchComponent implements OnInit {
  @Input() mapStyling: boolean = false;
  @Input() shrinkWhenClosed: boolean = false;
  @Input() resultFiltering: 'airports' | 'waypoints' | 'aircraft' | 'none' = 'none'; // TODO: REMOVE
  @ViewChild(FlyInputComponent) private readonly input: FlyInputComponent;

  private readonly airportContent = inject(AIRPORT_CONTENT_SIGNAL);
  private readonly airportDataService = inject(AirportDataService);
  private readonly airportService = inject(AirportService);
  private readonly authService = inject(AuthService);
  private readonly breakpointObserver = inject(BreakpointObserver);
  private readonly cdr = inject(ChangeDetectorRef);
  private readonly destroyRef = inject(DestroyRef);
  private readonly el = inject(ElementRef);
  private readonly flightRouteService = inject(FlightRouteControllerService);
  private readonly formBuilder = inject(FormBuilder);
  private readonly http = inject(HttpClient);
  private readonly infoService = inject(InfoService);
  private readonly mapService = inject(AvcMapsDisplayService);
  private readonly radialMenuService = inject(AvcMapRadialMenuService, { optional: true });
  private readonly acdcRealtimeTrafficService = inject(AcdcRealtimeTrafficService);

  readonly SEARCH_DEBOUNCE = 500;
  readonly Icons = Icons;
  readonly FlightIcons = FlightIcons;
  readonly State = State;

  protected showSearch: boolean = true;
  protected showSearchContent: boolean = false;
  private readonly isInternetTrafficEnabled = false;
  protected enterPressed: boolean = false;

  clickStartedInside = false;

  // TODO: why form group?
  formGroup = new FormGroup({
    searchControl: this.formBuilder.control<string>('', { nonNullable: true })
  });

  isMobileOrTabletSize: boolean = false;
  currentSearchState: State = State.NoSelection;

  isAuthenticated = toSignal(this.authService.isAuthenticated(), { initialValue: false });

  results = signal<SearchResult[]>([]);

  constructor() {
    effect(() => {
      const results = this.results();
      if (this.currentSearchState !== State.NoSelection) {
        if (results.length > 0) {
          this.currentSearchState = State.Loaded;
        } else {
          this.currentSearchState = State.NoDataAvailable;
        }
      }
    });
  }

  ngOnInit(): void {
    this.formGroup.controls.searchControl.valueChanges.pipe(
      (takeUntilDestroyed(this.destroyRef)),
      tap((value) => {
        if (!isStringNonEmpty(value)) {
          this.currentSearchState = State.NoSelection;
          return;
        } else {
          this.currentSearchState = State.Loading;
        }
      }),
      debounceTime(this.SEARCH_DEBOUNCE),
      filter((search): search is string => search !== ''),
      switchMap((search) => {
        const airports = this.resultFiltering !== 'waypoints' && this.resultFiltering !== 'aircraft' ? this.airportService.searchAirports(search) : of(null);
        const waypoints = this.resultFiltering !== 'airports' && this.resultFiltering !== 'aircraft' ? this.getWaypointInfo(search) : of(null);
        const aircraft = this.isInternetTrafficEnabled && this.resultFiltering !== 'waypoints' && this.resultFiltering !== 'airports' ? this.acdcRealtimeTrafficService.getTrafficTrackByRegistration(search): of(null);
        return combineLatest({
          airportsResponse: airports,
          waypointsResponse: waypoints,
          aircraftResponse: aircraft
        });
      })
    ).subscribe(({ airportsResponse, waypointsResponse, aircraftResponse }) => {
      this.results.set(this.sortResults(airportsResponse, waypointsResponse, aircraftResponse));
      // Select airport/waypoint if the enter key has been pressed.
      const formValue = this.formGroup.value.searchControl;
      if (this.enterPressed && isStringNonEmpty(formValue)) {
        for (const response of this.results()) {
          if (response.airport?.icao === formValue?.toUpperCase()) {
            this.onSelectAirport(response.airport!);
          }
          if (response.waypoint?.identifier === formValue?.toUpperCase()) {
            this.onSelectWaypoint(response.waypoint!);
          }
        }
      }
      // reset the enter flag
      this.enterPressed = false;
    });

    this.breakpointObserver.observe(Breakpoints.MediumScreenMaxWidth)
      .pipe(
        distinctUntilChanged(),
        takeUntilDestroyed(this.destroyRef)
      ).subscribe(({ matches }) => {
        this.isMobileOrTabletSize = matches;
        if (this.shrinkWhenClosed) {
          this.showSearch = this.isShowingContent || !this.isMobileOrTabletSize;
        }
      });

    /**
     * If a click starts (via the mousedown event) inside and ends
     * outside, the click event will be fired from the closest common
     * ancestor of each:
     *
     * https://developer.mozilla.org/en-US/docs/Web/API/Element/click_event
     *
     * We still want the show/hide behavior to happen after mouseup,
     * so ignore the case when the click starts inside and is dragged
     * outside before releasing the mouse button.
     */
    fromEvent(document, 'mousedown')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((event: any) => {
        this.clickStartedInside = this.containsEvent(event);
      });

    fromEvent(document, 'click')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((event: any) => {
        let parent = event.target.parentNode;
        let infoClicked = false;

        // Don't close the search bar if we clicked airport/waypoint info
        if (this.mapStyling) {
          while (parent != null && !infoClicked) {
            if (parent.className === 'info-column') {
              infoClicked = true;
              this.radialMenuService?.closeIfOpen();
            }
            parent = parent.parentNode;
          }
        }

        if (!this.containsEvent(event) && !this.clickStartedInside && !infoClicked) {
          this.hideContent();

          if (this.shrinkWhenClosed && this.isMobileOrTabletSize) {
            this.showSearch = false;
          }
        }

        event.stopPropagation();
      });
  }

  get isShowingContent(): boolean {
    return this.showSearchContent;
  }

  /**
   * Checks if an event originated from inside this component,
   * including the fly-icon inside the fly-input, which is not part
   * of the node tree.
   *
   * @param event an event from `fromEvent`, such as a click or mousedown event
   * @returns true if the event came from inside this component, otherwise false
   */
  containsEvent(event: any): boolean {
    return (this.el.nativeElement.contains(event.target) === true
      || [
        event.target.className, // <fly-icon>
        event.target.parentNode?.className, // <svg>
        event.target.parentNode?.parentNode?.className // <use>
      ].includes('cancel-icon'));
  }

  hideContent(): void {
    if (this.radialMenuService != null && this.showSearchContent) {
      this.radialMenuService.searchHideContent();
    }
    this.showSearchContent = false;
  }

  showContent(): void {
    if (this.radialMenuService != null && !this.showSearchContent) {
      this.radialMenuService.searchShowContent();
    }
    this.showSearchContent = true;
  }

  onOpenSearch(): void {
    this.showSearch = true;
    this.cdr.detectChanges();
    this.input.inputEl.nativeElement.focus();
  }

  onSelectAirport(airport: AirportSearchResult): void {
    const airportId = airport.icao ?? airport.iata ?? airport.naa ?? '';

    this.airportDataService.setAirportId(airportId);
    this.airportContent.set({ path: AirportContentPath.MetarTaf });

    // Update map
    this.mapService.highlight.location.set([{ latitude: airport.lat, longitude: airport.lon }]);
    this.mapService.state.fit({
      point: { latitude: airport.lat, longitude: airport.lon },
      minZoom: 10,
      maxZoom: 10
    });

    this.formGroup.controls.searchControl.setValue('');
    this.results.set([]);
    this.showSearchContent = false;
  }

  onSelectWaypoint(waypoint: RouteComputedLeg): void {
    this.infoService.showWaypoint(waypoint, InfoWindowPosition.TopRight);

    // Update map
    if (waypoint.lat != null && waypoint.lon != null) {
      this.mapService.highlight.location.set([{ latitude: waypoint.lat, longitude: waypoint.lon }]);
    }
    this.mapService.state.fit({
      point: { latitude: waypoint.lat ?? 0, longitude: waypoint.lon ?? 0 },
      minZoom: 10,
      maxZoom: 10
    });

    this.formGroup.controls.searchControl.setValue('');
    this.results.set([]);
    this.showSearchContent = false;
    this.showSearch = false;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onSelectAircraft(aircraft: AcdcAircraftTrackResponse): void {
    //turn on traffic overlay
    this.mapService.config.overlay.internetTraffic.visible.set(true);

    //get updated position
    this.acdcRealtimeTrafficService.getTrafficTrackByRegistration(aircraft.id)
    .pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe(
      (updatedPosition: AcdcAircraftTrackResponse | null) => {
        if (updatedPosition != null) {
          const aircraftPostion = updatedPosition.position.at(-1);
          if (aircraftPostion != null) {
            const internetTrafficInfo: InternetTrafficInfo = {
              id: updatedPosition.id,
              reg: updatedPosition.reg,
              callsign: updatedPosition.callsign,
              speed: aircraftPostion.speed,
              heading: aircraftPostion.heading,
              origin: updatedPosition.origin,
              destination: updatedPosition.destination,
              altitude: aircraftPostion.altitude,
              timestamp: aircraftPostion.timestamp,
              lat: aircraftPostion.lat ?? 0,
              lon: aircraftPostion.lon ?? 0,
              ground: aircraftPostion.ground,
              aircraft_type: updatedPosition.aircraft_type,
              point: {
                latitude: aircraftPostion.lat ?? 0,
                longitude: aircraftPostion.lon ?? 0
              }
            };
            //open tracking window
            this.infoService.showInternetTraffic(internetTrafficInfo, InfoWindowPosition.BottomRight);
        }
      }}
    );

    this.formGroup.controls.searchControl.setValue('');
    this.results.set([]);
    this.showSearchContent = false;
  }

  selectFirstResult(): void {
    const firstResult = this.results().at(0);
    if (firstResult?.airport != null) this.onSelectAirport(firstResult.airport);
    else if (firstResult?.waypoint != null) this.onSelectWaypoint(firstResult.waypoint);
  }

  onCancelSearch(): void {
    this.formGroup.controls.searchControl.setValue('');
    if (this.resultFiltering === 'airports') {
      this.showSearch = false;
      this.showSearchContent = false;
    }
  }

  getWaypointInfo(waypointId?: string, countryCode?: string, exactSearch?: boolean): Observable<UnifiedTokenResponse> {
    if (waypointId != null) {
      const request: UnifiedTokenRequest = {
        name: waypointId,
        countryCode,
        exactSearch: exactSearch ?? false,
        // Ignore the airports found from here, since we have the airport service
        desiredLocationTypes: TOKEN_SEARCH_TYPES.filter((token) =>
          token !== UnifiedTokenRequestDesiredLocationTypes.AIRPORT)
      };
      if (this.isAuthenticated()) {
        return this.flightRouteService.unifiedTokenSearchPost(request);
      } else {
        return this.http.post<UnifiedTokenResponse>(
          `${environment.garmin.aviation.workerApiHost}/flights/token-search-unauthenticated`,
          request
        );
      }
    } else {
      console.error('Unable to search for waypoint with no identifier');
      return of({ resultsList: [] });
    }
  }

  sortResults(
    airportsResponse: AirportSearchResponse | null,
    waypointsResponse: UnifiedTokenResponse | null,
    aircraftResponse: AcdcAircraftTrackResponse | null
  ): SearchResult[] {
    const airports = airportsResponse?.airports ?? [];
    const waypoints = waypointsResponse?.resultsList ?? [];
    const aircrafts = aircraftResponse != null ? [aircraftResponse]: [];
    const results = [
      ...airports.map((airport) => ({ airport } as SearchResult)),
      ...waypoints.map((waypoint) => ({ waypoint } as SearchResult)),
      ...aircrafts.map((aircraft) => ({ aircraft } as SearchResult))
    ];

    const moveToFront = (array: any[], index: number): number => array.unshift(array.splice(index, 1)[0]);
    const searchText = this.formGroup.controls.searchControl.value;

    let i = 0;
    for (const result of results) {
      const airport = result.airport;
      const waypoint = result.waypoint;
      const aircraft = result.aircraft;
      if (airport != null
        && [airport.icao, airport.iata, airport.naa].includes(searchText.toUpperCase())) {
        moveToFront(results, i);
      } else if (waypoint != null && waypoint.identifier === searchText.toUpperCase()) {
        moveToFront(results, i);
      } else if (aircraft != null && aircraft.id.toUpperCase() === searchText.toUpperCase()){
        moveToFront(results, i);
      }
      i += 1;
    }
    return results;
  }
}
