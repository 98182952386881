<div class="container"
  [class.map]="mapStyling">
  <button
    flyButton
    class="open-search-btn"
    [class.hidden]="showSearch"
    (click)="onOpenSearch()"
    title="Open search"
    aria-label="Open search"
    [options]="{ style: 'map', size: 'map-icononly' }">
    <fly-icon [icon]="Icons.Search"></fly-icon>
  </button>

  <div
    class="search"
    [class.hidden]="!showSearch">
    <form [formGroup]="formGroup">
      <div class="search-input"
        [class.map]="mapStyling">
        <fly-input formControlName="searchControl"
          [autocomplete]="false"
          (focusin)="showContent()"
          (focusout)="selectFirstResult()"
          (keydown.enter)="enterPressed = true"
          name="search"
          placeholder="Search">
          <fly-icon [icon]="Icons.Search"
            slot="icon-left"></fly-icon>
          @if (currentSearchState !== State.NoSelection  || resultFiltering === 'airports') {
            <fly-icon class="cancel-icon"
              [icon]="Icons.Cancel"
              (click)="onCancelSearch()"
              slot="icon-right"></fly-icon>
          }
        </fly-input>
      </div>
    </form>
  </div>

  @if (showSearchContent) {
    <div class="search-content tab-item"
      [class.map]="mapStyling"
      flyIgnoreMapClick>

      @switch (currentSearchState) {
        @case (State.Loaded) {
          <div class="loaded-results">
            @for (result of results(); track $index) {
              @if (result.airport) {
                <pilot-airport-search-card
                  (selected)="onSelectAirport($event.airport)"
                  [airport]="result.airport">
                </pilot-airport-search-card>
              } @else if (result.waypoint) {
                <pilot-search-card
                  (selected)="onSelectWaypoint($event.waypoint)"
                  [waypoint]="result.waypoint">
                </pilot-search-card>
              } @else if (result.aircraft) {
                <pilot-aircraft-search-card
                  (selected)="onSelectAircraft($event)"
                  [aircraft]="result.aircraft">
                </pilot-aircraft-search-card>
              }
            }
          </div>
        }

        @case (State.Loading) {
          <fly-loading-spinner message="Retrieving data...">
          </fly-loading-spinner>
        }

        @case (State.NoDataAvailable) {
          <div>No results found.</div>
        }

        @case (State.NoSelection) {
          <div class="explanation">
            <span class="body-1-semibold">Search for things like...</span>
            <ul>
              <li>
                <span class="explain-left body-2-semibold">KFCM</span>
                <span class="explain-right body-2"> - Find waypoints by identifier</span>
              </li>
              <li>
                <span class="explain-left body-2-semibold">Minneapolis</span>
                <span class="explain-right body-2"> - Find by city name</span>
              </li>
              <li>
                <span class="body-explain-left body-2-semibold">Flying Cloud</span>
                <span class="explain-right body-2"> - Find by facility name</span>
              </li>
            </ul>
          </div>
        }
      }
    </div>
  }
</div>
